import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import GalleryLayout from "./galleryLayout"

function Wardrobe() {
  return (
    <GalleryLayout title="Wardrobe">
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512126/wardrobe/wardrobe-1_j760zh.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512130/wardrobe/wardrobe-2_imttdy.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512125/wardrobe/wardrobe-3_lphhrd.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512128/wardrobe/wardrobe-4_f3toe5.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512124/wardrobe/wardrobe-5_irajnv.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512128/wardrobe/wardrobe-6_av1ps5.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512128/wardrobe/wardrobe-7_tfqumx.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512133/wardrobe/wardrobe-8_lca5fg.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512133/wardrobe/wardrobe-9_yrbnci.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512131/wardrobe/wardrobe-10_tbplpk.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512132/wardrobe/wardrobe-12_bglioj.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512133/wardrobe/wardrobe-11_hgrt1s.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512130/wardrobe/wardrobe-13_pblmrc.jpg"
        alt="attires"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512129/wardrobe/wardrobe-14_wwqqf0.jpg"
        alt="attires"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512131/wardrobe/wardrobe-15_mux8xq.jpg"
        alt="attires"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512131/wardrobe/wardrobe-16_auvrdg.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512132/wardrobe/wardrobe-17_x0ahbt.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512132/wardrobe/wardrobe-18_n6tsk7.jpg"
        alt="attires"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512132/wardrobe/wardrobe-19_rzksfm.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512133/wardrobe/wardrobe-20_rpuajh.jpg"
        alt="attires"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512133/wardrobe/wardrobe-21_rkylj5.jpg"
        alt="attires"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512133/wardrobe/wardrobe-22_kpd0mv.jpg"
        alt="attires"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512133/wardrobe/wardrobe-23_q4qwet.jpg"
        alt="attires"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512133/wardrobe/wardrobe-24_cr8m9u.jpg"
        alt="attires"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1605512134/wardrobe/wardrobe-25_tyu8mk.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606124748/wardrobe/wardrobe-26_ntmtgf.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606124748/wardrobe/wardrobe-27_zqdzj8.jpg"
        alt="attires"
        className="gallery-picture-v"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606124748/wardrobe/wardrobe-28_tqouyz.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606124748/wardrobe/wardrobe-29_rfe9g5.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606124748/wardrobe/wardrobe-30_xthpia.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606124748/wardrobe/wardrobe-31_izjfac.jpg"
        alt="attires"
        className="gallery-picture"
      />
      <StaticImage
        src="https://res.cloudinary.com/diw3segy3/image/upload/g_auto,c_fill,f_auto/v1606124747/wardrobe/wardrobe-32_regc5q.jpg"
        alt="attires"
        className="gallery-picture"
      />
    </GalleryLayout>
  )
}

export default Wardrobe
